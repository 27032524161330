const settings = {
  general: {
    production: false, // If is set to true, then debug and other dev functionalities will be disabled.
    debug: true, // Enables debug log for the whole project
    checkForDocumentReady: 500, // Interval checking if the document is ready. in miliseconds

    iframeDomHandler: 1500, // Interval checking the iframeDomHandler. in miliseconds
    customLessonTimerOverrideInMs: null, // For testing purpsoses, if you want to skip the waiting time; To turn off either put `0` or `null`
    disableVideosWatched: false, // Disable video checks, if `true` the `video.ended` checks will be skipped.
    disableIframeIsQuizCheck: false, // If is set to true, will not check if a page is a quiz page.
    disableIsLastAvailableLessonCheck: true, // !WARNING this setting is not affected by production setting! If is set to true, will not check if a page is the last lesson
    disableAxiosPostRequest: false, // if true post request after lesson complettition will not be sent
    playAudioOnTimerNextButtonRelease: { playAudioOnNextButtonRelease: false, audioFileUrl: '/templates/ja_educatsy/local/js/other/short-success-sound-glockenspiel-treasure-video-game.mp3' }, // Play a sound when the next button is released, to help with multiple window debugging
    playAutoOnTimerZeroSeconds: { playAudioOnTimerZero: false, audioFileUrl: '/templates/ja_educatsy/local/js/other/success-fanfare-trumpets.mp3' }, // Play a sound when the timer reaches 00:00 | The URL should not have the base URL
  },

  quiz: {
    quizPassingThreshold: 70, // Student successfully passed the test if he scored over. Default value 70 (%);
    quizScoreRegex: '/d+/',
    quizSelector: 'quiz', // Check if part of the iframe src contains quiz. If yes, it means that it's a quiz page. Case insensitive
  },

  selectors: {
    lessonIframe: '#frame-lesson-content', // Lesson Iframe
    lessonOpenModalButton: '[id^=d-lesson-]', // Button opening a lesson
    guruLessonFooterNav: '.guru-lesson-footer-nav', // Lesson Footer, contains next/prev buttons
    footer: '#guru-lesson-footer',
    footerNext: '.guru-lesson-footer-next', // Lesson Footer next lesson button
    footerPrev: '.guru-lesson-footer-prev', // Lesson Footer prev lesson button
    counterContainer: '#counter-container', // The container div of the timer
    lessonTextContent: '.gru-lesson-content', // Selects the course container selector
    lessonInfographicContent: `[data-imgtype=infographic]`, // NOTE: This is TEMPLATE LITTERAL
    closeLessonButton: '.guru-lesson-home', // Close lesson iframe button selector
    chapterQuizSelector: '.gru-myquizzesstats', // Selector for the final lesson per chapter quizes.
    personalQuizSubmitButton: '#nextBtn',
    retakeQuizButtonSelector: '[value="Retake"]', // If quiz is failed, but student can retake
    quizSubmitButton: '.guru-quiz__btn', // End of Chapter quiz, submit button selector
    guruQuizScore: '.guru-quiz__header--alt span', // To determine if a quiz is passed or not
    guruQuizFailedPermanently: '.no-attempts-left', // When a student fails the quiz permanently after 3 failed attempts
    quizPassedCongratulations: '.wk-alert-success', // When quiz successfully passed, and congratulation message appeared.
    isSatisfactionForm: '.student-satisfaction-form', // Student Satisfaction form is the last lesson of the course
    timer: '.timer',
    videoNodeListSelector: 'video', // Video elements contained in the course
    videoControlsContainerSelector: '.video-controls-container', // Selector of the video controls container div
  },

  videoHandling: {
    rewindTimeInMs: 5000, // Pressing rewind will rewind the video for XXXX miliseconds
    videoDurationAttribute: 'vdrms', // To overcome issues with some videos durations, each video must have an extra attribute that has the video duration in miliseconds
    videoWatchedMessage: 'Video Watched', // When a video is watched, a text will be displayed in the custom video controls box
    startVideoPaused: true, // If set to true, the videos will start paused. Set to true recommended
  },

  timer: {
    wordsPerMinute: 150,
    infographicTimeDuration: 60000,

    helpToggleContent: `<p>Each lesson of this course has a minimum time requirement, in order for you to read and understand the content.
    When the timer reaches zero, and if you've watched any videos the lesson might have, you will be able to proceed.</p>

    <p>If the lesson has been completed and the Next button doesn't seem to work, please refresh the page and open the next lesson manually.</p>    
      
    <p>In case you need any help with our course please contact us:</p>
    <p>Phone: (718) 400-7400 <br/> 8.00 AM - 4.00 PM Mon-Fri</p>
    <p>E-mail: <br/> info@pre-licensingcourse.com</p>`, // NOTE: This is TEMPLATE LITTERAL
  },

  isLastAvailableLesson: {
    openableLessonsSelector: `viewed.gif`, // NOTE: This is TEMPLATE LITTERAL
  },

  axiosPostRequest: {
    postDataType: 'formData', // if the value is `'formData'` then the datatype will be formData otherwise will be JSON
    serverUrl: 'https://gblus.com', // Base website URL
    postRequestURL: '/index.php?option=com_api&format=raw&app=guru&resource=setlessonstatus', // AXIOS POST request URL
    headers: {
      JSON: 'application/json', // Make sure that data type is JSON
      formData: 'multipart/form-data', // OBSOLETE
    },
  },

  observers: {
    lessonIframeOpenedObserver: {
      settings: { childList: true },
      observationInterval: 1500, // Interval checking the globalObserverHandler. in miliseconds
    },
    lessonIframeUpdatedObserver: {
      settings: { attributes: true, attributeFilter: ['src'], attributeOldValue: true, characterData: false, characterDataOldValue: false, childList: false, subtree: true },
    },
    chapterQuizStateUpdatedObserver: {
      settings: { childList: true, subtree: true },
    },
  },
};

export { settings };
