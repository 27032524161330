import { settings } from '../settings';
import { dbg } from '../debugger';
import { selectors } from '../selectors';
import { lessonsMap, mapLessons } from './mapLessons';

const isLastAvailableLesson = () => {
  // const iframe = document.querySelector(settings.selectors.lessonIframe);

  const { iframe } = selectors();

  if (iframe) {
    // const firstNonAvailableLesson = lessonsMap.find((lesson) => lesson.lessonWatched === false);
    const lastAvailableLesson = lessonsMap.findLast((lesson) => lesson.lessonWatched === true);
    const lastAvailableLessonCid = lastAvailableLesson.lessonId; // keep only numbers
    const isLastLessonAvailable = iframe.src.includes(lastAvailableLessonCid) ? true : false;

    // Calling mapLessons
    dbg(mapLessons(), { isTable: true });

    dbg(`isLastAvailableLesson({ lastLesson: ${isLastLessonAvailable}, lastLessonId: ${lastAvailableLessonCid}  })`);

    if (settings.general.production) {
      return isLastLessonAvailable; // If the course id is the last openableLessonCid, then return true else return false
    } else {
      return !settings.general.disableIsLastAvailableLessonCheck ? isLastLessonAvailable : true; // If the last available lesson check setting is disabled, always return true.
    }
  } else {
    dbg('islastAvailableLesson() not updated, iframe closed');
    return;
  }
};

export { isLastAvailableLesson };
