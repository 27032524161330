import { formatTime } from './formatTime';
import { playSound } from '../debugger';

// Countdown timer, renders the HTML element in DOM.
const countDown = (countDownTime, element) => {
  element.classList.add('timer');
  let remainingMilliseconds = countDownTime - Date.now();

  if (remainingMilliseconds > 0) {
    element.innerHTML = '<strong>' + formatTime(remainingMilliseconds) + '</strong>';
    element.setAttribute('datatimer', formatTime(remainingMilliseconds));
  } else {
    element.innerHTML = '<strong>00:00</strong>';
    element.setAttribute('datatimer', '00:00');
    playSound('timer'); // Will play a sound when timer is 00:00
    return true;
  }

  setTimeout(() => {
    countDown(countDownTime, element);
  }, 1000);
};

export { countDown };
