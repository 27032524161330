import { dbg } from '../debugger';
import { settings } from '../settings';

// Experimental not in use. The purpose is to remove the timer element when someone clicks prev/next button

const destroyTimerElement = (iframeDoc) => {
  const counterContainer = document.querySelector(settings.selectors.counterContainer) ? document.querySelector(settings.selectors.counterContainer) : null;

  counterContainer != null && counterContainer.remove();
  counterContainer != null && dbg('counterContainer removed');
  return;
};

export { destroyTimerElement };
