import { dbg } from './../debugger';
import { settings } from '../settings';

const saveLessonDetails = () => {
  // dbg('saveLessonDetails()');
  const iframe = document.querySelector(settings.selectors.lessonIframe);

  if (iframe) {
    const courseIdRegex = new RegExp('(?:cid=)(.*?)-');
    const courseSrc = iframe.src.toString();

    const itemId = courseSrc.match(courseIdRegex)[1];
    const openedLesson = document.querySelector('#d-lesson-' + itemId);
    dbg(`saveLessonDetails({
            itemId: ${itemId}, 
            user_id: ${openedLesson.getAttribute('uid')},
            program_id: ${openedLesson.getAttribute('pid')},
            module_id: ${openedLesson.getAttribute('mid')},
            lesson_id: ${openedLesson.getAttribute('cid')},
          }
        )`);
    // dbg(
    //   {
    //     user_id: openedLesson.getAttribute('uid'),
    //     program_id: openedLesson.getAttribute('pid'),
    //     module_id: openedLesson.getAttribute('mid'),
    //     lesson_id: openedLesson.getAttribute('cid'),
    //   },
    //   { table: true }
    // );
    return {
      user_id: openedLesson.getAttribute('uid'),
      program_id: openedLesson.getAttribute('pid'),
      module_id: openedLesson.getAttribute('mid'),
      lesson_id: openedLesson.getAttribute('cid'),
    };
  } else {
    dbg('saveLessonDetails() not updated, iframe closed');
    return;
  }
};

export { saveLessonDetails };
