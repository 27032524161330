// import { allVideosWatched } from '../releaseNextChapter/allVideosWatched';
import { nextButtonHandling } from '../releaseNextChapter/nextButtonHandling';
import { releaseNextChapter } from '../releaseNextChapter/releaseNextChapter';
import { saveLessonDetails } from '../releaseNextChapter/saveLessonDetails';
import { calculateInfographic } from './../calculators/calculateInfographic';
import { calculateText } from './../calculators/calculateText';
import { calculateVideo } from './../calculators/calculateVideo';
import { createTimerElement } from './../countDownTimer/createTimerElement';
import { isLastAvailableLesson } from './isLastAvailableLesson';
import { iframeIsChapterQuiz, isPersonalQuiz, chapterQuizDisplayFooter } from './iframeIsQuiz';
import { isSatisfactionForm } from './isSatisfactionForm';
import { dbg } from './../debugger';
import { selectors } from '../selectors';
import { settings } from './../settings';
import { displayLessonFooter } from '../releaseNextChapter/displayLessonFooter';
import { prevButtonHandling } from '../releaseNextChapter/prevButtonHandling';
import { updateLastAvailableLesson } from './updateLastAvailableLesson';
import { chapterQuizStateUpdatedHandler } from './chapterQuizStateUpdatedObserver';

import { lessonIframeUpdatedHandler } from './lessonIframeUpdatedObserver';
import { closeLessonWindowHandling } from './_closeLessonWindowHandling';

// Function that runs after iframe loaded.
const afterLoading = () => {
  dbg('afterLoading()');

  const { lessonTextContent, lessonInfographicContent, videoNodeListSelector, quizSubmitButton } = selectors();

  lessonIframeUpdatedHandler();
  prevButtonHandling(); // load previous button handling
  closeLessonWindowHandling();
  // nextButtonHandling(false);

  // iframeIsChapterQuiz() && chapterQuizDisplayFooter();

  // If it is not a quiz and is  last available lesson
  if (isLastAvailableLesson() && !iframeIsChapterQuiz() && !isSatisfactionForm()) {
    dbg('afterLoading !iframeIsChapterQuiz() + isLastAvailableLesson()');

    dbg('Loading Timer');
    displayLessonFooter(true);

    // Target lesson text content
    // Only one at the time querySelector per lesson
    const textTotalTimeInMs = calculateText(lessonTextContent);

    // Target lesson infographic content
    // Multiple items per lesson, so use querySelectorAll, to retrieve a nodelist
    const infographicsTotalTimeInMs = lessonInfographicContent ? calculateInfographic(lessonInfographicContent) : 0;

    // Target lesson Video content
    // Multiple items per lesson, so use querySelectorAll , to retrieve a nodelist of iframes (that contain the actual video)
    // const videoNodeList = iframeDoc.querySelectorAll('[type="video/mp4"]');
    const videoNodeList = videoNodeListSelector;

    const videoTotalTimeCalculatedInMs = videoNodeListSelector ? calculateVideo() : 0;

    // Calculate total time in Miliseconds
    const totalTimeInMiliseconds = textTotalTimeInMs + infographicsTotalTimeInMs + videoTotalTimeCalculatedInMs;

    dbg('Lesson Duration: ');
    dbg(
      {
        textTotalTimeInMs: textTotalTimeInMs,
        infographicsTotalTimeInMs: infographicsTotalTimeInMs,
        videoTotalTimeCalculatedInMs: videoTotalTimeCalculatedInMs,
        totalTimeInMiliseconds: totalTimeInMiliseconds,
      },
      { table: true }
    );

    createTimerElement(typeof settings.general.customLessonTimerOverrideInMs === 'number' && !settings.general.production ? settings.general.customLessonTimerOverrideInMs : totalTimeInMiliseconds);

    setTimeout(
      () => {
        dbg('Lesson timer setTimeout() executed');
        releaseNextChapter(videoNodeList, saveLessonDetails());
      },
      settings.general.customLessonTimerOverrideInMs == 0 || settings.general.customLessonTimerOverrideInMs == null ? totalTimeInMiliseconds : settings.general.customLessonTimerOverrideInMs
    );
  } else if (iframeIsChapterQuiz() && !isPersonalQuiz() && !isSatisfactionForm() && isLastAvailableLesson()) {
    dbg('afterLoading iframeIsChapterQuiz(true) + isLastAvailableLesson(true)');
    // chapterQuizDisplayFooter();
    // chapterQuizStateUpdatedHandler();
    displayLessonFooter(true);
    nextButtonHandling(true);
    // quizSubmitButton.onclick = () => chapterQuizDisplayFooter();
    // dbg(quizSubmitButton);
    // nextButtonHandling(true);
    // const footerNext = document.querySelector(settings.selectors.footerNext);
    updateLastAvailableLesson();
    // footerNext.onclick = () => {
    //   dbg('Next Button Clicked');
    //   nextButtonHandling(false);
    //   dbg('nextButtonHandling() return');

    //   return;
    // };
    return;
  } else if (isPersonalQuiz() && !isSatisfactionForm()) {
    dbg(`afterLoading isPersonalQuiz()`);
    displayLessonFooter(false);
  } else if (isSatisfactionForm()) {
    displayLessonFooter(false);
  } else {
    dbg(`afterLoading else`);
    displayLessonFooter(true);
    nextButtonHandling(true);
    const footerNext = document.querySelector(settings.selectors.footerNext);

    footerNext.onclick = () => {
      dbg('Next Button Clicked');
      nextButtonHandling(false);
      dbg('nextButtonHandling() return');
      return;
    };
  }
  dbg('afterLoading() return');
  dbg('');

  return;
};

export { afterLoading };
