import { dbg } from '../debugger';
import { selectors } from '../selectors';

const allVideosWatched = (videoNodeList) => {
  const { videoNodeListSelector } = selectors();

  // If there are videos
  if (videoNodeListSelector) {
    let videosWatchedList = [];
    videoNodeList.forEach((video) => videosWatchedList.push(video.dataset.watched));

    return videosWatchedList.length > 0 ? videosWatchedList.every((element) => element === 'true') : true;
  }

  // else return true
  return true;
};

export { allVideosWatched };
