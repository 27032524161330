import { playPause } from './playPause';
import { rewind } from './rewind';
import { videoWatchedNotification } from './videoWatchedNotification';
import { settings } from '../settings';
import { destroyVideoControlsContainer } from './destroyVideoControlsContainer';
import { dbg } from '../debugger';
import { selectors } from '../selectors';

// Edit the div, and add it to the DOM
const createVideoControlsContainer = (videoNode, videoId, iframeDoc) => {
  dbg('createVideoControlsContainer()');

  // const videoControlsClass = 'video-controls-container';
  // Create the a div
  // Hide video Controls for every Video
  videoNode.removeAttribute('controls');
  // Disable Right click on every video
  videoNode.addEventListener('contextmenu', (event) => event.preventDefault());

  const videoControlsContainerDiv = document.createElement('div');

  destroyVideoControlsContainer(iframeDoc); // if it exists destroy it

  videoControlsContainerDiv.classList.add(settings.selectors.videoControlsContainerSelector.split('.').join(''), videoId); // videoControlsContainerSelector from settings has a class `.` in front of it, but here needs to be removed

  // Custom video controls
  playPause(videoNode, videoControlsContainerDiv, false);

  // Rewind
  rewind(videoNode, videoControlsContainerDiv, settings.videoHandling.rewindTimeInMs);

  // Only display when the video has ended
  videoNode.addEventListener('ended', (e) => {
    videoWatchedNotification(videoControlsContainerDiv, videoId, settings.videoHandling.videoWatchedMessage);
  });

  videoNode.id = videoId;
  const nodeVideoElement = iframeDoc.getElementById(videoNode.id);

  !document.querySelector(settings.videoHandling.videoControlsContainerSelector) && nodeVideoElement.parentNode.insertBefore(videoControlsContainerDiv, nodeVideoElement.nextSibling);

  // return !document.querySelector(settings.videoHandling.videoControlsContainer) && videoControlsContainerDiv;
  return;
};

export { createVideoControlsContainer };
