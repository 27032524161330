import { settings } from '../settings';

const calculateText = (element, wordsPerMinute = settings.timer.wordsPerMinute) => {
  const oneMinute = 60000; // miliseconds

  // Strip blanks and empty lines
  const stripSpaces = element.innerText.replace(/^\s*$[\r\n]*|^[^\S\r\n]+|[^\S\r\n]+$|([^\S\r\n]){2,}/gm, '');
  // Split string when // Split string when
  let count = stripSpaces.split(' ').length;
  const get_text = (el) => {
    ret = '';

    const length = el.childNodes.length;
    for (let i = 0; i < length; i++) {
      const node = el.childNodes[i];

      if (node.nodeType != 8) {
        ret += node.nodeType != 1 ? node.nodeValue : get_text(node);
      }
    }
    return ret;
  };

  const time = (count / wordsPerMinute) * oneMinute;

  return parseInt(time); // Returns time in miliseconds
};

export { calculateText };
