import { settings } from '../settings';
import { dbg } from '../debugger';

const mapLessons = () => {
  const chapters = document.querySelectorAll('.tab-content .guru-tabs');

  let lessonsMap = []; // A map of lesson objects

  chapters.forEach((chapter) => {
    const lessons = chapter.querySelectorAll('li');
    lessons.forEach((lesson) => {
      const lessonOpenModalButton = lesson.querySelector(settings.selectors.lessonOpenModalButton);

      const lessonWatched = lessonOpenModalButton.onclick.toString().includes(settings.isLastAvailableLesson.openableLessonsSelector);

      lessonsMap.push({ lessonId: parseInt(lessonOpenModalButton.id.replace(/\D/g, '')), lessonTitle: lessonOpenModalButton.innerHTML.trim(), lessonWatched: lessonWatched });

      // Lessons that contain the open lesson functions
    });
  });

  //   dbg(lessonsMap.find((lesson) => lesson.lessonId === parseInt(courseId)));
  //   dbg(lessonsMap, { table: true });

  return lessonsMap;
};

const lessonsMap = mapLessons();
dbg('Original Lessons Map');
dbg(lessonsMap, { table: true });

export { mapLessons, lessonsMap };
