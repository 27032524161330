import { settings } from '../settings';

import { checkIframeLoaded } from '../handleIframe/checkIframeLoaded';
import { dbg } from '../debugger';
import { displayLessonFooter } from '../releaseNextChapter/displayLessonFooter';
import { nextButtonHandling } from '../releaseNextChapter/nextButtonHandling';

const lessonIframeUpdatedObserver = new MutationObserver(function (mutations) {
  mutations.some(function (mutation) {
    dbg('mutations.some triggered');
    if (mutation.attributeName === 'src') {
      dbg(`lessonIframeUpdatedHandler(${mutation.target.src})`);

      // dbg({ oldSrc: mutation.oldValue, newSrc: mutation.target.src, mutation: mutation }, { table: true });

      const currentTimer = document.querySelector('#counter-container');

      currentTimer && currentTimer.remove(); // if currentTimer does not exist, don't try to remove

      displayLessonFooter(false);
      nextButtonHandling(false);

      // setTimeout(() => checkIframeLoaded(), 2000);
      checkIframeLoaded();

      dbg('lessonIframeUpdatedHandler() return');

      return true;
    }

    dbg('lessonIframeUpdatedHandler() return');

    return false;
  });
});

const lessonIframeUpdatedHandler = () => {
  lessonIframeUpdatedObserver.observe(document.body, settings.observers.lessonIframeUpdatedObserver.settings);

  // lessonIframeUpdated.disconnect();

  return;
};

export { lessonIframeUpdatedHandler, lessonIframeUpdatedObserver };
