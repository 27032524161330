import { allVideosWatched } from './allVideosWatched';
import { nextButtonHandling } from './nextButtonHandling';
import { dbg } from './../debugger';
import { settings } from '../settings';
import { selectors } from '../selectors';
import { destroyTimerElement } from '../countDownTimer/destroyTimerElement';
import { updateLastAvailableLesson } from '../handleIframe/updateLastAvailableLesson';
import { isLastAvailableLesson } from '../handleIframe/isLastAvailableLesson';
import { destroyVideoControlsContainer } from '../videoControls/destroyVideoControlsContainer';

const releaseNextChapter = (videoNodeList, lessonDetails) => {
  const { timer, footerNext } = selectors();

  if (timer) {
    const dataTimer = timer.getAttribute('datatimer');

    dbg(`releaseNextChapter(${dataTimer})`);
    let dataTimerArray = [];

    if (dataTimer == '00:00' || dataTimer == '0:00') {
      // dbg('Data Timer: ' + dataTimer);
      dbg(dataTimerArray);
      // dbg('All videos are watched and data timer is 00:00' + allVideosWatched(videoNodeList) && dataTimer == '00:00');
      // const footerNext = document.querySelector(settings.selectors.footerNext);

      const { user_id, program_id, module_id, lesson_id } = lessonDetails;
      if (allVideosWatched(videoNodeList) || (settings.general.disableVideosWatched && !settings.general.production)) {
        // formDataPayload.append('complete', 1);
        destroyTimerElement();
        nextButtonHandling(true); // Enable next button

        // Axios POST request
        if (!settings.general.production && !settings.general.disableAxiosPostRequest) {
          // Create JSON Payload (LEGACY)
          const JSONpayload = {
            user_id: parseInt(user_id),
            program_id: parseInt(program_id),
            module_id: parseInt(module_id),
            lesson_id: parseInt(lesson_id),
            complete: 1,
          };

          // Create formData. The server does not accept JSON, so we send the data as formData
          const FormData = require('form-data');
          const formDataPayload = new FormData();
          formDataPayload.append('user_id', parseInt(user_id));
          formDataPayload.append('program_id', parseInt(program_id));
          formDataPayload.append('module_id', parseInt(module_id));
          formDataPayload.append('lesson_id', parseInt(lesson_id));

          const axios = require('axios').default;
          const postAddress = settings.axiosPostRequest.serverUrl + settings.axiosPostRequest.postRequestURL;
          dbg('Axios POST');
          axios
            .post(postAddress, settings.axiosPostRequest.postDataType === 'formData' ? formDataPayload : JSON.stringify(JSONpayload), {
              headers: {
                'Content-Type': settings.axiosPostRequest.postDataType === 'formData' ? settings.axiosPostRequest.headers.formData : settings.axiosPostRequest.headers.JSON,
              },
            })
            .then((response) => {
              dbg('Axios Data:');
              dbg(
                {
                  error: `${response.data.err_msg ? response.data.err_msg : 'No error detected'}`,
                  postURL: postAddress,
                  dataType: response.config.headers['Content-Type'],
                  responseData: response.config.data,
                  rawResponse: response,
                },
                { table: true }
              );
            })
            .catch((error) => {
              console.error(error);
            });

          dbg('Adding onclick to footerNext (After POST)');
          dbg('updateLastAvailableLesson call not passed yet');
          updateLastAvailableLesson();
          dbg('updateLastAvailableLesson call passed');
          footerNext.onclick = () => {
            dbg('Next Button Clicked');
            // nextButtonHandling(false);
          };
        } else {
          dbg('updateLastAvailableLesson call not passed yet');

          updateLastAvailableLesson();

          destroyVideoControlsContainer(); // if it exists destroy it
          dbg('updateLastAvailableLesson call passed');
          dbg('Adding onclick to footerNext (POST skipped)');
          footerNext.onclick = () => {
            dbg('Next Button Clicked');

            // nextButtonHandling(false);
          };

          dbg('Axios Post request is disabled in the settings.js');
        }
      }
    } else {
      // dbg('setTimeout Data Timer: ' + dataTimer);

      dataTimerArray = dataTimerArray.push.dataTimer;
      window.setTimeout(() => releaseNextChapter(videoNodeList, lessonDetails), 1000);
    }
  }

  return;
};

export { releaseNextChapter };
