import { afterLoading } from './afterLoading';
import { settings } from '../settings';
import { selectors } from '../selectors';
import { dbg } from '../debugger';
import { nextButtonHandling } from '../releaseNextChapter/nextButtonHandling';
import { isLastAvailableLesson } from './isLastAvailableLesson';
import { iframeIsChapterQuiz } from './iframeIsQuiz';
import { displayLessonFooter } from '../releaseNextChapter/displayLessonFooter';

// src: https://stackoverflow.com/questions/9249680/how-to-check-if-iframe-is-loaded-or-it-has-a-content
const checkIframeLoaded = () => {
  dbg('checkIframeLoaded()');

  !isLastAvailableLesson() && iframeIsChapterQuiz() && nextButtonHandling(true); // Reenable next button if this is not the last course

  // const iframe = document.querySelector(settings.selectors.lessonIframe); // Course Iframe element from the main DOM
  // const iframeDoc = iframe.contentDocument || iframe.contentWindow.document; // Course iframe element document

  const { iframeDoc } = selectors();

  // Check if loading is complete

  // Check when readyState is true, but also check the length of the body. If its 1, Guru still needs to load stuff.
  if (iframeDoc.readyState === 'complete' && iframeDoc.childNodes.length > 1) {
    // The loading is complete, call the function we want executed once the iframe is loaded
    displayLessonFooter(false);
    nextButtonHandling(false);
    afterLoading();
    dbg('checkIframeLoaded() return');
    dbg('');
    return;
  }

  // If we are here, it is not loaded. Set things up so we check   the status again in XXXX milliseconds
  window.setTimeout(() => checkIframeLoaded(), 500);
  return;
};

export { checkIframeLoaded };
