import { settings } from '../settings';

import { checkIframeLoaded } from './checkIframeLoaded';
import { dbg } from '../debugger';
import { displayLessonFooter } from '../releaseNextChapter/displayLessonFooter';
import { nextButtonHandling } from '../releaseNextChapter/nextButtonHandling';
import { selectors } from '../selectors';

const chapterQuizStateUpdatedObserver = new MutationObserver(function (mutations) {
  dbg('chapterQuizStateUpdatedObserver()');

  for (let mutation of mutations) {
    // console.log(mutation);
    if (mutation.type === 'childList') {
    }
  }
});

const chapterQuizStateUpdatedHandler = () => {
  dbg('chapterQuizStateUpdatedHandler()');
  const { iframeDoc } = selectors();
  const toObserve = iframeDoc;
  dbg(toObserve);
  chapterQuizStateUpdatedObserver.observe(toObserve, settings.observers.chapterQuizStateUpdatedObserver.settings);
  dbg('Observing');

  // chapterQuizStateUpdated.disconnect();
};

export { chapterQuizStateUpdatedHandler, chapterQuizStateUpdatedObserver };
