import { settings } from '../settings';

const calculateInfographic = (infographicsNodeList, timePerInfographihcInMs = settings.timer.infographicTimeDuration) => {
  let time = 0;
  infographicsNodeList.forEach((infographic) => {
    // For each infographic add timePerInfogeraphicInMs
    time = time + timePerInfographihcInMs;
  });
  return parseInt(time);
};

export { calculateInfographic };
