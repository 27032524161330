import { settings } from './settings';
import { dbg } from './debugger';

const selectors = () => {
  const iframe = document.querySelector(settings.selectors.lessonIframe); // Course Iframe element from the main DOM
  const iframeDoc = iframe ? iframe.contentDocument || iframe.contentWindow.document : null;

  const guruLessonFooterNav = document.querySelector(settings.selectors.guruLessonFooterNav); // Lesson Footer contains next/prev buttons
  const footer = document.querySelector(settings.selectors.footer);
  const footerNext = document.querySelector(settings.selectors.footerNext); // Lesson Footer next lesson button
  const footerPrev = document.querySelector(settings.selectors.footerPrev); // Lesson Footer prev lesson button
  const counterContainer = document.querySelector(settings.selectors.counterContainer); // The container div of the timer
  const lessonTextContent = iframeDoc.querySelector(settings.selectors.lessonTextContent); // Selects the course container selector
  const lessonInfographicContent = iframeDoc.querySelectorAll(settings.selectors.lessonInfographicContent); // NOTE= This is TEMPLATE LITTERAL
  const closeLessonButton = document.querySelector(settings.selectors.closeLessonButton); // Close lesson iframe button selector

  const personalQuizSubmitButton = document.querySelector(settings.selectors.personalQuizSubmitButton);

  const chapterQuizSelector = document.querySelector(settings.selectors.chapterQuizSelector);
  const retakeQuizButtonSelector = iframeDoc.querySelector(settings.selectors.retakeQuizButtonSelector); // If quiz is failed but student can retake
  const quizSubmitButton = iframeDoc.querySelector(settings.selectors.quizSubmitButton); // End of Chapter quiz, submit button selector
  const guruQuizScore = iframeDoc.querySelector(settings.selectors.guruQuizScore);
  const quizPassedCongratulations = iframeDoc.querySelector(settings.selectors.quizPassedCongratulations);
  // const guruQuizFailed = document.querySelector(settings.selectors.guruQuizFailed);
  const guruQuizFailedPermanently = iframeDoc.querySelector(settings.selectors.guruQuizFailedPermanently);
  const satisfactionForm = iframeDoc.querySelector(settings.selectors.isSatisfactionForm);

  const lessonOpenModalButton = document.querySelector(settings.selectors.lessonOpenModalButton);
  const timer = document.querySelector(settings.selectors.timer);
  const videoNodeListSelector = iframeDoc.querySelectorAll(settings.selectors.videoNodeListSelector); // Video elements contained in the course
  const videoControlsContainerSelector = document.querySelector(settings.selectors.videoControlsContainerSelector); // Selector of the video controls container div

  const selectors = {
    body: document.querySelector('body'),
    iframe: iframe ? iframe : null,
    iframeDoc: iframeDoc ? iframeDoc : null,
    guruLessonFooterNav: guruLessonFooterNav ? guruLessonFooterNav : null,
    footer: footer ? footer : null,
    footerNext: footerNext ? footerNext : null,
    footerPrev: footerPrev ? footerPrev : null,
    counterContainer: counterContainer ? counterContainer : null,
    lessonTextContent: lessonTextContent ? lessonTextContent : null,
    lessonInfographicContent: lessonInfographicContent ? lessonInfographicContent : null,
    closeLessonButton: closeLessonButton ? closeLessonButton : null,
    guruQuizScore: guruQuizScore ? guruQuizScore : null,
    quizPassedCongratulations: quizPassedCongratulations ? quizPassedCongratulations : null,
    // guruQuizFailed: guruQuizFailed ? guruQuizFailed : null,
    guruQuizFailedPermanently: guruQuizFailedPermanently,
    satisfactionForm: satisfactionForm ? satisfactionForm : null,
    personalQuizSubmitButton: personalQuizSubmitButton ? personalQuizSubmitButton : null,
    chapterQuizSelector: chapterQuizSelector ? chapterQuizSelector : null,
    retakeQuizButtonSelector: retakeQuizButtonSelector ? retakeQuizButtonSelector : null,
    quizSubmitButton: quizSubmitButton ? quizSubmitButton : null,
    lessonOpenModalButton: lessonOpenModalButton ? lessonOpenModalButton : null,
    timer: timer ? timer : null,
    videoNodeListSelector: videoNodeListSelector ? videoNodeListSelector : null,
    videoControlsContainerSelector: videoControlsContainerSelector ? videoControlsContainerSelector : null,
  };

  // dbg(selectors, { table: true });
  return selectors;
};

export { selectors };
