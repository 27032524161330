import { settings } from '../settings';
import { lessonIframeOpenedObserver } from './lessonIframeOpenedObserver';
import { lessonIframeUpdatedObserver } from './lessonIframeUpdatedObserver';
import { dbg } from '../debugger';

const closeLessonWindowHandling = () => {
  const closeButton = document.querySelector(settings.selectors.closeLessonButton);

  closeButton.onclick = () => {
    dbg('Lesson Window Closed');
    // lessonIframeOpenedObserver.disconnect();
    // lessonIframeUpdatedObserver.disconnect();

    // dbg('Observers Disconnected');
  };

  return;
};

export { closeLessonWindowHandling };
