const createHelpToggle = (divContainer, helpToggleContent) => {
  // Help Content
  const helpContent = document.createElement('span');
  helpContent.innerHTML = helpToggleContent;
  helpContent.id = 'help-content-container';
  helpContent.style.display = 'none';

  // Help toggle
  const helpToggleButton = document.createElement('button');
  helpToggleButton.innerHTML = '?';
  helpToggleButton.classList.add('help-toggle');
  helpToggleButton.addEventListener('click', () => (helpContent.style.display === 'none' ? (helpContent.style.display = 'block') : (helpContent.style.display = 'none')));

  divContainer.appendChild(helpToggleButton);
  divContainer.appendChild(helpContent);
};

export { createHelpToggle };
