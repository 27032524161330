import { dbg } from '../debugger';
import { settings } from '../settings';
import { selectors } from '../selectors';

const destroyVideoControlsContainer = () => {
  const { videoControlsContainerSelector } = selectors();

  // const iframe = document.querySelector(settings.selectors.lessonIframe); // Course Iframe element from the main DOM
  // const iframeDoc = iframe.contentDocument || iframe.contentWindow.document; // Course iframe element document
  // const videoControlsContainer = iframeDoc.querySelector(settings.videoHandling.videoControlsContainerSelector);
  videoControlsContainerSelector && dbg(`destroyVideoControlsContainer(${videoControlsContainerSelector})`);
  videoControlsContainerSelector != null && videoControlsContainerSelector.remove();
  return;
};

export { destroyVideoControlsContainer };
