import { settings } from '../settings';
import { dbg } from '../debugger';
import { nextButtonHandling } from '../releaseNextChapter/nextButtonHandling';
import { checkIframeLoaded } from './checkIframeLoaded';

// GLOBAL OBSERVER
const lessonIframeOpenedObserver = new MutationObserver((entry) => {
  const iframe = document.querySelector(settings.selectors.lessonIframe);
  // Check if lesson html is loaded / unloaded

  if (iframe) {
    // Just to control weather the console log will display or not
    dbg('Lesson iframe loaded');

    nextButtonHandling(false);
    // displayLessonFooter(false);
    // Create the mutation we are listening out for
    checkDocumentReady();

    return;
  } else {
    // Just to control weather the console log will display or not
    dbg('Lesson iframe not loaded');
    return;
  }
});

const lessonIframeOpenedHandler = () => {
  dbg('lessonIframeOpenedHandler()');

  // Observers
  // Select the entire DOM to observe:
  const globalObserverTarget = document.querySelector('body');

  lessonIframeOpenedObserver.observe(globalObserverTarget, settings.observers.lessonIframeOpenedObserver.settings);
};

// Wait for document to load
const checkDocumentReady = () => {
  if (document.readyState === 'complete') {
    checkIframeLoaded();
    dbg('checkDocumentReady() returns');
    return;
  }
  window.setTimeout(checkDocumentReady, settings.general.checkForDocumentReady);
};

export { lessonIframeOpenedHandler, lessonIframeOpenedObserver };
