import { settings } from '../settings';

const videoWatchedNotification = (videoControlsContainerDiv, videoId, message = settings.videoHandling.videoWatchedMessage) => {
  // This checks weather the notification already exists. If it exists it does nothing, otherwise it displays the notification
  if (videoControlsContainerDiv.querySelector('.watched-notification-' + videoId) === null) {
    const createVideoWatchedNotificationContainer = document.createElement('div');
    createVideoWatchedNotificationContainer.classList.add('watched-notification-' + videoId);
    createVideoWatchedNotificationContainer.innerHTML = message;

    videoControlsContainerDiv.appendChild(createVideoWatchedNotificationContainer);
  }
};

export { videoWatchedNotification };
