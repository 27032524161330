import { dbg } from '../debugger';

const rewind = (currentVideoHTMLElement, videoControlsContainerDiv, timeInMiliseconds = 5000) => {
  const rewindButton = document.createElement('button');
  dbg('rewind()');
  rewindButton.classList.add('rewind');

  rewindButton.addEventListener('click', (e) => {
    const currentVideoTime = currentVideoHTMLElement.currentTime * 1000;
    const rewindTimeCalculatedInSeconds = (currentVideoTime - timeInMiliseconds) / 1000;

    if (rewindTimeCalculatedInSeconds > 0) {
      currentVideoHTMLElement.currentTime = rewindTimeCalculatedInSeconds;
    } else {
      currentVideoHTMLElement.currentTime = 0;
    }
  });

  rewindButton.addEventListener('mouseover', () => (rewindButton.style.cursor = 'pointer'));

  rewindButton.style.padding = '0.2rem';
  rewindButton.style.backgroundColor = 'red';
  rewindButton.style.color = 'white';
  rewindButton.style.textTransform = 'uppercase';
  rewindButton.style.borderRadius = '4px';

  videoControlsContainerDiv.appendChild(rewindButton);
};

export { rewind };
