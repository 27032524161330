import { createHelpToggle } from './createHelpToggle';
import { countDown } from './countDownTimer';
import { settings } from '../settings';
import { destroyTimerElement } from './destroyTimerElement';
import { dbg } from '../debugger';
import { nextButtonHandling } from '../releaseNextChapter/nextButtonHandling';

const createTimerElement = (totalTimeInMiliseconds) => {
  dbg('createTimerElement()');
  // Check if there is a timer element already and remove it if it does, to avoid having douplicate timers
  destroyTimerElement();
  nextButtonHandling(false);
  // Setup new div element to add next to next/prev buttons
  const divCounterContainer = document.createElement('div');
  divCounterContainer.id = 'counter-container';

  // Target Guru Footer
  const guruLessonFooterNav = document.querySelector(settings.selectors.guruLessonFooterNav);
  guruLessonFooterNav.append(divCounterContainer);

  // Create the timer element, and the help icon element
  const divTimer = document.createElement('div');
  divTimer.id = 'time';
  divCounterContainer.prepend(divTimer);

  // const timerElementContainer = document.createElement('divTimer');
  const timerElementSpan = document.createElement('span');
  let countDownTime = Date.now() + totalTimeInMiliseconds;
  countDown(countDownTime, timerElementSpan);

  // Add to the DOM
  divCounterContainer.appendChild(timerElementSpan);

  createHelpToggle(divCounterContainer, settings.timer.helpToggleContent);
};

export { createTimerElement };
