import { settings } from './settings';

const dbg = (message, config = { muteInternalDebugger: false, trace: false, table: false }) => {
  if (settings === null) {
    console.error('dbg called but settings are not available. Please pass settings as the second parameter');
  } else {
    if (config.table) {
      !settings.general.production && settings.general.debug && !config.muteInternalDebugger && (config.trace ? console.trace(message) : console.table(message));
      // console.table(message);
    } else {
      !settings.general.production && settings.general.debug && !config.muteInternalDebugger && (config.trace ? console.trace(message) : console.log(message));
      // console.log(message);
      // !settings.general.production && settings.general.debug && console.log(message);
    }
  }
};

const playSound = (type) => {
  if (type === 'nextButton' && settings.general.playAudioOnTimerNextButtonRelease.playAudioOnNextButtonRelease && !settings.general.production) {
    const audio = new Audio(settings.axiosPostRequest.serverUrl + settings.general.playAudioOnTimerNextButtonRelease.audioFileUrl);
    dbg(`Playing: ${settings.axiosPostRequest.serverUrl + settings.general.playAudioOnTimerNextButtonRelease.audioFileUrl}`);
    audio.play();
  } else if (type === 'timer' && settings.general.playAutoOnTimerZeroSeconds.playAudioOnTimerZero && !settings.general.production) {
    const audio = new Audio(settings.axiosPostRequest.serverUrl + settings.general.playAutoOnTimerZeroSeconds.audioFileUrl);
    dbg(`Playing: ${settings.axiosPostRequest.serverUrl + settings.general.playAutoOnTimerZeroSeconds.audioFileUrl}`);
    audio.play();
  }
  return;
};

export { dbg, playSound };
