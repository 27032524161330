import { settings } from '../settings';
import { selectors } from '../selectors';
import { dbg } from '../debugger';
import { lessonIframeUpdatedHandler } from '../handleIframe/lessonIframeUpdatedObserver';

const prevButtonHandling = () => {
  dbg('prevButtonHandling()');
  // const footerPrev = document.querySelector(settings.selectors.footerPrev);
  const { footerPrev } = selectors();

  footerPrev.addEventListener('click', () => {
    dbg('Prev button clicked');
    // lessonIframeUpdatedHandler();
    dbg('prevButtonHandling() return');
  });
};

export { prevButtonHandling };
