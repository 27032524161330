import { dbg } from '../debugger';
import { settings } from '../settings';
import { selectors } from '../selectors';
import { displayLessonFooter } from '../releaseNextChapter/displayLessonFooter';

// Detects if it's a quiz in general (could be chapter quiz or personal quiz)
const iframeIsChapterQuiz = () => {
  const { iframe, iframeDoc, quizSubmitButton } = selectors();
  const iframeSrc = iframe.src;
  // Some iframeSrc instances have the word quiz with capital letters and some with lowercase. Thus i convert everything to lowercase making the selector case insensitive
  const quizInURLString = settings.quiz.quizSelector.toLowerCase(); // If the iframeSrc URL contains this it means it is a Quiz and so the timer should not load.
  const isIframeQuiz = iframeSrc.toLowerCase().contains(quizInURLString) ? true : false;

  const disableIframeIsQuizCheck = settings.general.disableIframeIsQuizCheck;

  // If quiz is not passed, hide the footer, if quiz is passed, show the IS NOT footer
  const endOfChapterIframeQuiz = iframeDoc.querySelector('.fontello-hourglass') ? true : false;
  // const randomQuiz = iframeDoc.querySelector('.counter-style');
  const quizIsNotPassed = endOfChapterIframeQuiz; // Check if Quiz Hourglass icon exists inside quiz iframe DOM. If yes, it means that the quiz is not passed, or check if it's random quiz.

  dbg(
    {
      isIframeQuiz: isIframeQuiz,
      quizRetakeButtonExists: quizRetakeButtonExists(),
    },
    { table: true }
  );

  if (settings.general.production) {
    return isIframeQuiz;
  } else {
    dbg(`iframeIsChapterQuiz(${!disableIframeIsQuizCheck ? isIframeQuiz : false})`);
    return !disableIframeIsQuizCheck ? isIframeQuiz : false; // If quiz page check is disabled in the settings always return false
  }
};

const chapterQuizDisplayFooter = () => {
  const { iframeDoc, guruQuizScore, guruQuizFailedPermanently, footerNext, quizPassedCongratulations } = selectors();
  displayLessonFooter(false);
  dbg(`chapterQuizDisplayFooter()`);

  const quizPassingThreshold = parseInt(settings.quiz.quizPassingThreshold);

  const quizScoreResults = guruQuizScore ? parseInt(guruQuizScore.innerHTML.replace(/\D/g, '')) : null;

  const lessonCongratulations = quizPassedCongratulations ? true : false;
  const quizPassed = quizScoreResults > quizPassingThreshold || lessonCongratulations;

  const quizFailedPermanently = guruQuizFailedPermanently ? true : false;
  quizPassed && displayLessonFooter(true);

  dbg(
    {
      quizPassingThreshold: quizPassingThreshold,
      quizScoreResults: quizScoreResults,

      quizPassed: quizPassed,
      quizFailedPermanently: quizFailedPermanently,
    },
    { table: true }
  );

  if (quizPassed) {
    // displayLessonFooter(true);
    // nextButtonHandling(true);

    footerNext.onclick = () => {
      dbg('Next Button Clicked');
      // nextButtonHandling(false);
      dbg('nextButtonHandling() return');

      return;
    };
  }

  guruQuizFailedPermanently && displayLessonFooter(false);

  return;
};

const quizRetakeButtonExists = () => {
  const { retakeQuizButtonSelector } = selectors();
  dbg(`retakeQuizButtonSelector(${retakeQuizButtonSelector})`);
  return retakeQuizButtonSelector ? true : false;
};

// Detect if it's a personal quiz
const isPersonalQuiz = () => {
  const iframe = document.querySelector(settings.selectors.lessonIframe); // Course Iframe element from the main DOM
  const iframeDoc = iframe.contentDocument || iframe.contentWindow.document; // Course iframe element document
  const iframeSrc = iframe.src;
  // Some iframeSrc instances have the word quiz with capital letters and some with lowercase. Thus i convert everything to lowercase making the selector case insensitive
  const quizInURLString = settings.quiz.quizSelector.toLowerCase(); // If the iframeSrc URL contains this it means it is a Quiz and so the timer should not load.
  const isIframeQuiz = iframeSrc.toLowerCase().contains(quizInURLString) ? true : false;
  const randomQuizButton = iframeDoc.querySelector('#nextBtn'); // The normal lesson Quiz Button
  const disableIframeIsQuizCheck = settings.general.disableIframeIsQuizCheck;

  dbg(`isPersonalQuiz(${isIframeQuiz && randomQuizButton ? true : false})`);

  if (settings.general.production) {
    return isIframeQuiz && randomQuizButton;
  } else {
    dbg(`iframeIsChapterQuiz(${!disableIframeIsQuizCheck ? isIframeQuiz : false})`);
    return !disableIframeIsQuizCheck ? isIframeQuiz && randomQuizButton : false; // If quiz page check is disabled in the settings always return false
  }
};

export { iframeIsChapterQuiz, isPersonalQuiz, chapterQuizDisplayFooter };
