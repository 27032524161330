import { settings } from '../settings';
import { selectors } from '../selectors';
import { dbg } from '../debugger';

const isSatisfactionForm = () => {
  const { satisfactionForm } = selectors();
  dbg(`isSatisfactionForm(${satisfactionForm ? true : false})`);

  return satisfactionForm ? true : false;
};

export { isSatisfactionForm };
