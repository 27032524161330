import { settings } from '../settings';
import { dbg } from '../debugger';

const displayLessonFooter = (displayFooter) => {
  const lessonFooter = document.querySelector(settings.selectors.footer);
  displayFooter ? (lessonFooter.style.display = 'block') : (lessonFooter.style.display = 'none');
  dbg(`displayLessonFooter(${displayFooter ? (lessonFooter.style.display = 'block') : (lessonFooter.style.display = 'none')})`);

  return;
};

export { displayLessonFooter };
