import { releaseNextChapter } from '../releaseNextChapter/releaseNextChapter';
import { saveLessonDetails } from '../releaseNextChapter/saveLessonDetails';
import { settings } from '../settings';
import { createVideoControlsContainer } from '../videoControls/createVideoControlsContainer';
import { dbg } from './../debugger';
import { selectors } from '../selectors';

let prevTime = 0;
let currentTimeArray = [0];

const calculateVideo = () => {
  // const videoNodeList = iframeDoc.querySelectorAll(settings.videoHandling.videoNodeListSelector);

  const { iframeDoc, videoNodeListSelector, videoDurationAttribute } = selectors();

  let videoDurationTotal = 0;

  dbg('videoNodeListSelector: ');
  dbg(videoNodeListSelector);

  videoNodeListSelector.forEach((videoNode, index) => {
    const videoId = 'video-' + index;
    videoNode.addEventListener('play', (e) => dbg(`play ${videoId}`));
    videoNode.addEventListener('playing', (e) => dbg(`playing ${videoId}`));
    videoNode.addEventListener('pause', (e) => dbg(`pause ${videoId}`));
    videoNode.addEventListener('ended', (e) => dbg(`Video ${videoId} Ended`));

    // dbg(videoNode.duration);

    // Create Controls Container

    createVideoControlsContainer(videoNode, videoId, iframeDoc);

    // Start the video Paused
    settings.videoHandling.startVideoPaused && videoNode.pause(); // Pause the video

    videoNode.addEventListener('timeupdate', (event) => currentTimeArray.push(event.target.currentTime));

    // Add Datawatch attribute
    videoNode.setAttribute('data-watched', 'false');
    videoNode.addEventListener('ended', (e) => videoNode.setAttribute('data-watched', 'true'));
    const lessonDetails = saveLessonDetails();
    videoNode.addEventListener('ended', (e) => releaseNextChapter(videoNodeListSelector, lessonDetails));
    // videoNode.addEventListener('ended', (e) => );

    const currentVideoDuration = videoNode.getAttribute(settings.videoHandling.videoDurationAttribute) ? videoNode.getAttribute(settings.videoHandling.videoDurationAttribute) : videoNode.duration * 1000; // Convert video duration to miliseconds
    dbg(`VDRMS: ${videoNode.getAttribute(settings.videoHandling.videoDurationAttribute)}`);
    videoDurationTotal += currentVideoDuration;

    // videoNode.addEventListener('timeupdate', (event) => (timeSkipped = checkSkipped(event.target.currentTime)));
    // videoNode.addEventListener('ended', (e) => dbg('Video Ended with ' + timeSkipped + ' time skipped'));
  });

  videoDurationTotal = videoDurationTotal; // Time in miliseconds

  // dbg(videoDurationTotal);

  return isNaN(videoDurationTotal) ? 0 : parseInt(videoDurationTotal);
};

const checkSkipped = (currentTime, skipThreshold = 1) => {
  // skipThreshold in seconds

  let skip = [];

  // user skipped part of the video
  if (currentTime - prevTime > skipThreshold) {
    skip = {
      periodSkipped: currentTime - prevTime,
      startAt: prevTime,
      endAt: currentTime,
    };
    prevTime = currentTime;
    return skip;
  }

  prevTime = currentTime;
  return false;
};

const percentageCompleted = (frequencyInMs = 500, videoNode) => {
  let percentageCompleted = [];

  if (videoNode.ended == true) {
    dbg('Ended True');
  } else if (videoNode.ended == false) {
    dbg('Ended false');
    setInterval(function () {
      percentageCompleted.push(videoNode.currentTime / videoNode.duration);

      dbg(percentageCompleted);
    }, frequencyInMs);
  }
};

// Migrated code to a function, not tested.
const disableFastForward = (videoNode, disableFastForward) => {
  if (disableFastForward) {
    videoNode.onseeking = (event) => {
      const beforeSeekTime = event.timeStamp / 1000; // From Miliseconds to seconds
      const currentTime = currentTimeArray[currentTimeArray.length - 1];
      const timeDifference = resetVideoTimeline(beforeSeekTime, currentTime);
      const finalTime = currentTime - timeDifference;

      // dbg('Before Seek Time: ');
      // dbg(beforeSeekTime);
      // dbg('Current Time: ');
      // dbg(currentTime);
      // dbg('Final Time: ');
      // dbg(finalTime);

      if (beforeSeekTime < currentTime) {
        // dbg('beforeSeekTime < currentTime');

        showAlert(iframeDoc); // Render HTML Alert to the user

        videoNode.currentTime = finalTime; // Move timeline back to timestamp before forward seeking
        videoNode.pause(); // Pause the video
      }
    };
  }
};

const showAlert = (iframeDoc, duration = 5000) => {
  const videoSkipWarning = document.createElement('div');

  videoSkipWarning.classList.add('video-alert');

  const alertText = iframeDoc.createTextNode('Fast-forwarding is not allowed');

  videoSkipWarning.appendChild(alertText);

  iframeDoc.body.appendChild(videoSkipWarning);

  videoSkipWarning.style.display = 'block';

  setTimeout(() => {
    videoSkipWarning.style.display = 'none';
  }, duration);
};

const resetVideoTimeline = (beforeSeekTime, currentTime) => {
  const timeDifference = currentTime - beforeSeekTime;
  // dbg('Before Seek Time: ');
  // dbg(beforeSeekTime);
  // dbg('Current Time: ');
  // dbg(currentTime);
  // dbg('Time Difference: ');
  // dbg(timeDifference);

  return parseInt(timeDifference);
};

export { calculateVideo };
