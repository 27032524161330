import { dbg } from '../debugger';

// Create Play Pause Custom Button
const playPause = (videoNode, videoControlsContainerDiv, isPlaying = false) => {
  const playPauseButton = document.createElement('button');
  playPauseButton.classList.add('play');

  playPauseButton.addEventListener('click', () => {
    if (isPlaying) {
      playPauseButton.classList.remove('pause');
      playPauseButton.classList.add('play');
      videoNode.pause();
      // dbg('video paused');
    } else {
      playPauseButton.classList.remove('play');
      playPauseButton.classList.add('pause');
      videoNode.play();
      // dbg('Video is playing');
    }

    // If true makes it false, and the oposite. Basically the toggle functionality.
    isPlaying = !isPlaying;
  });

  // Redisplay Play Icon when video has ended
  videoNode.addEventListener('ended', (e) => {
    playPauseButton.classList.remove('pause');
    playPauseButton.classList.add('play');
    dbg('Video watched');
  });

  playPauseButton.addEventListener('mouseover', () => (playPauseButton.style.cursor = 'pointer'));
  playPauseButton.style.padding = '0.2rem';
  playPauseButton.style.backgroundColor = 'red';
  playPauseButton.style.color = 'white';
  playPauseButton.style.textTransform = 'uppercase';
  playPauseButton.style.borderRadius = '4px';

  videoControlsContainerDiv.appendChild(playPauseButton);
};

export { playPause };
