import { lessonIframeUpdatedHandler } from '../handleIframe/lessonIframeUpdatedObserver';
import { settings } from '../settings';
import { dbg, playSound } from '../debugger';
import { selectors } from '../selectors';

const nextButtonHandling = (isNextEnabled) => {
  // Make Next Button Disabled by default
  // const footerNext = document.querySelector(settings.selectors.footerNext);

  const { footerNext } = selectors();

  // isNextEnabled && lessonIframeUpdatedHandler();

  setTimeout(() => {
    footerNext.disabled = isNextEnabled ? false : true;
    dbg(`nextButtonHandling(${isNextEnabled ? false : true})`);
    isNextEnabled && playSound('nextButton'); // If enabled in settings and not in production a sound will play when next button is enabled.
  }, 1000);

  return;
};

export { nextButtonHandling };
