import { dbg } from '../debugger';
import { lessonsMap } from './mapLessons';

const updateLastAvailableLesson = () => {
  const lastAvailableLesson = lessonsMap.findLast((lesson) => lesson.lessonWatched === true);
  const lastAvailableLessonCid = lastAvailableLesson.lessonId; // keep only numbers

  const index = lessonsMap.findIndex((object) => {
    return object.lessonId === lastAvailableLessonCid;
  });

  lessonsMap[index + 1].lessonWatched = true;
  dbg(`updateLastAvailableLesson(${lessonsMap[index + 1]})`);
  dbg('Updated Lessons Map');
  dbg(lessonsMap, { table: true });

  return;
};

export { updateLastAvailableLesson };
